// import 'bootstrap/dist/css/bootstrap.css';
// // import "mdb-react-ui-kit/dist/css/mdb.min.css";
// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App';
// // import registerServiceWorker from './registerServiceWorker';
// import { unregister } from './registerServiceWorker';
// //try this with unregister and comment out registerServiceWorker in production for fresh update


// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
// const rootElement = document.getElementById('root');

// const root = ReactDOM.createRoot(rootElement);

// root.render(
//   <BrowserRouter basename={baseUrl}>
//     <App />
//   </BrowserRouter>,
//   );

// // registerServiceWorker();
// unregister()


import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import { unregister } from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
// var baseUrl = (document.querySelector('base') || {}).href

// var baseUrl = (document.querySelector('base') || {}).href

const rootElement = document.getElementById('root')

// console.log("basename", baseUrl)

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

unregister()



// import 'bootstrap/dist/css/bootstrap.css';
// import React from 'react';
// import * as ReactDOM from 'react-dom/client';
// import App from 'App';
// import { unregister } from './registerServiceWorker';

// // const container = document.getElementById('root');

// // // Create a root.
// // const root = ReactDOMClient.createRoot(container);


// const root = ReactDOM.createRoot(
//   document.getElementById('root')
// );

// // Initial render: Render an element to the root.
// root.render(<App />);

// // // During an update, there's no need to pass the container again.
// // root.render(<App tab="profile" />);

// unregister()

