import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
//import "./custom.css";
//Pages that should load at app launch
import Login from "./components/login/Login";
import Home from "./components/directory/Home";

// const load = (Component: any) => (props: any) => (
//   <Suspense fallback={<Loader />}>
//     <Component {...props} />
//   </Suspense>
// );

//UTILS (DO NOT REMOVE)
const PrivateRoute = lazy(() => import("./utils/privateRoute"));

//accounts
const AccountList = lazy(() => import("./components/accounts/AccountList"));
const FullAccountList = lazy(() => import("./components/accounts/FullAccountList"));
const DeletedAccounts = lazy(() => import("./components/accounts/DeletedAccounts"));
const CreateAccount = lazy(() => import("./components/accounts/CreateAccount"));
const AccountInfo = lazy(() => import("./components/accounts/AccountInfo"));
const MobileDesktopAccountPage = lazy(() => import("./components/accounts/mobile-desktop-account-page"))


//admin
const AdminTools = lazy(() => import("./components/admin/AdminTools"));
const SoftDelete = lazy(() => import("./components/admin/SoftDelete"));
const UnDelete = lazy(() => import("./components/admin/UnDelete"));

//cases
const ViewCases = lazy(() => import("./components/cases/ViewCases"));
const CasePage = lazy(() => import("./components/cases/Case-Page"));
const PostCases = lazy(() => import("./components/cases/PostCases"));

//commissions
const SalesBonusPage = lazy(() => import("./components/commissions/internalcommissions/SalesBonusPage"));
const SalesBonusAdminPage = lazy(() => import("./components/commissions/internalcommissions/SalesBonusAdminPage"));
const CommissionRuleViewer = lazy(() => import("./components/commissions/internalcommissions/internalcommissionrules/CommissionRuleViewer"));
const SalesBonusDetail = lazy(() => import("./components/commissions/internalcommissions/internalcommissioncomputation/SalesBonusDetail"));
const InternalSalesCommissionPayout = lazy(() => import("./components/commissions/internalcommissions/internalcommissionpayments/InternalSalesCommissionPayout"));
const InternalSalesBonusCommissionPayoutTest = lazy(() => import("./components/commissions/internalcommissions/internalcommissionpayments/InternalSalesBonusCommissionPayoutTest"));

//customerorders
const COLinkPage = lazy(() => import("./components/customerorders/co-link-page"));
const ViewCustomerOrdersPage = lazy(() => import("./components/customerorders/view-customer-orders-page"));
const ViewCustomerOrderPage = lazy(() => import("./components/customerorders/view-customer-order-page"));
const LinkCOfromVisualPage = lazy(() => import("./components/customerorders/link-co-from-visual-page"));

//customers
const CustomerTable = lazy(() => import("./components/customers/CustomerTable"));
const CustomerInvoiceDetail = lazy(() => import("./components/customers/CustomerInvoiceDetail"));
const CustomerHome = lazy(() => import("./components/customers/CustomerHome"));

//devtools
const DevToolsHome = lazy(() => import("./components/devtools/DevToolsHome"));
const FolderCheck = lazy(() => import("./components/devtools/FolderCheck"));

//directory

//edi
const EdiOrders = lazy(() => import("./components/edi/EdiOrders"));
const PurchaseOrder = lazy(() => import("./components/edi/PurchaseOrder"));

//feed
const FollowFeed = lazy(() => import("./components/feed/FollowFeed"));

//finance
const RMAPrice = lazy(() => import("./components/finance/RMAPrice"));

//forecasting
const ForecastSummaryByYear = lazy(() => import("./components/forecasting/ForecastSummaryByYear"));
const ForecastUserGroups = lazy(() => import("./components/forecasting/forecastcontrolpanel/ForecastUserGroups"));
const ForecastAllGroups = lazy(() => import("./components/forecasting/forecastcontrolpanel/ForecastAllGroups"));
const ShowRevisions = lazy(() => import("./components/forecasting/forecastcontrolpanel/ShowRevisions"));
const ForecastSubmissions = lazy(() => import("./components/forecasting/forecastaccounts/ForecastSubmissions"));
const ArrowTest = lazy(() => import("./components/forecasting/forecasttest/ArrowTest"));
const Forecast = lazy(() => import("./components/forecasting/forecasting-page"));
const ForecastControlPanelPage = lazy(() => import("./components/forecasting/forecast-controlpanel-page"));
const ForecastSummaryDetailPage = lazy(() => import("./components/forecasting/forecasting-summary-detail-page"));
const ForecastSummaryDetail = lazy(() => import("./components/forecasting/ForecastSummaryDetail"));
const ForecastSummaryPreview = lazy(() => import("./components/forecasting/ForecastSummaryPreview"));
const ForecastSummaryPivot = lazy(() => import("./components/forecasting/forecastpivot/ForecastSummaryPivot"));
const ForecastSummaryRevenue = lazy(() => import("./components/forecasting/forecastreports/ForecastSummaryRevenue"));
const ForecastAnnualUsageUpdate = lazy(() => import("./components/forecasting/forecastreports/ForecastAnnualUsageUpdate"));


//forms

//groups
const GroupList = lazy(() => import("./components/groups/GroupList"));
const Group = lazy(() => import("./components/groups/Group"));

//help
const Help = lazy(() => import("./components/help/Help"));
const NotFound = lazy(() => import("./components/help/NotFound"));
const Latest = lazy(() => import("./components/help/Latest"));
const CRMFeedback = lazy(() => import("./components/help/CRMFeedback"));
const CRMSourcePass = lazy(() => import("./components/help/CRMSourcePass"));

//historiccrm
const HistoricTypes = lazy(() => import("./components/historiccrm/HistoricTypes"));
const HistoricTable = lazy(() => import("./components/historiccrm/HistoricTable"));
const HistoricRecord = lazy(() => import("./components/historiccrm/HistoricRecord"));
const HistoricOpportunities = lazy(() => import("./components/historiccrm/importanttables/HistoricOpportunity"))
const HistoricTasks = lazy(() => import("./components/historiccrm/importanttables/HistoricTasks"));
const HistoricUserTasks = lazy(() => import("./components/historiccrm/importanttables/HistoricUserTasks"));
const HistoricUsers = lazy(() => import("./components/historiccrm/importanttables/HistoricUsers"));
const HistoricAccounts = lazy(() => import("./components/historiccrm/importanttables/HistoricAccounts"));
const HistoricLeads = lazy(() => import("./components/historiccrm/importanttables/HistoricLeads"));
const HistoricTask = lazy(() => import("./components/historiccrm/importanttables/HistoricTask"));
const HistoricAccount = lazy(() => import("./components/historiccrm/importanttables/HistoricAccount"));
const HistoricCases = lazy(() => import("./components/historiccrm/importanttables/HistoricCases"));

//leads
const AllLeads = lazy(() => import("./components/leads/AllLeads"));
const UsersLeads = lazy(() => import("./components/leads/UsersLeads"));
const CreateLead = lazy(() => import("./components/leads/CreateLead"));
const LeadPage = lazy(() => import("./components/leads/lead-page"));
const AllContactData = lazy(() => import("./components/leads/contactdata/AllContactData"));
const ContactData = lazy(() => import("./components/leads/contactdata/ContactData"));
const UnprocessedContactData = lazy(() => import("./components/leads/contactdata/UnprocessedContactData"));
const AllSpam = lazy(() => import("./components/leads/contactdata/AllSpam"));

//log
const LogAll = lazy(() => import("./components/log/LogAll"));
const MasterLog = lazy(() => import("./components/log/MasterLog"));
const DevLog = lazy(() => import("./components/log/DevLog"));
const UpdateDevLog = lazy(() => import("./components/log/UpdateDevLog"));

//login

//marketing
const ViewAllMarketExpenses = lazy(() => import("./components/marketing/marketexpense/ViewAllMarketExpenses"));
const UpdateMarketExpense = lazy(() => import("./components/marketing/marketexpense/UpdateMarketExpense"));
const CreateMarketExpense = lazy(() => import("./components/marketing/marketexpense/CreateMarketExpense"));
const MarketExpense = lazy(() => import("./components/marketing/marketexpense/MarketExpense"));
const AllSds = lazy(() => import("./components/marketing/sds/AllSds"));
const MarketingTools = lazy(() => import("./components/marketing/MarketingTools"));
const AllManuals = lazy(() => import("./components/marketing/manuals/AllManuals"));
const ManualPage = lazy(() => import("./components/marketing/manual-page"));
const CreateManualPage = lazy(() => import("./components/marketing/create-manual-page"));
const SdsPage = lazy(() => import("./components/marketing/sds-page"));
const CreateSDSPage = lazy(() => import("./components/marketing/create-sds-page"));
const ProcessPartImages = lazy(() => import("./components/marketing/automation/ProcessPartImages"));

//meetings
const Meeting = lazy(() => import("./components/meetings/Meeting-Page"));
const CreateMeetingPage = lazy(() => import("./components/meetings/Create-Meeting-Page"));

//notifications
const NotificationLog = lazy(() => import("./components/notifications/NotificationLog"));

//opportunities
const CreateOpportunity = lazy(() => import("./components/opportunities/CreateOpportunity"));
const UpdateOpportunity = lazy(() => import("./components/opportunities/UpdateOpportunity"));
const AllOpportunities = lazy(() => import("./components/opportunities/opportunitylists/AllOpportunities"));
const UsersOpportunities = lazy(() => import("./components/opportunities/opportunitylists/UsersOpportunitites"));
const PutNote = lazy(() => import("./components/opportunities/PutNote"));
const CreateNote = lazy(() => import("./components/opportunities/CreateNote"));
const OpportunitySplitScr = lazy(() => import("./components/opportunities/OpportunitySplitScr"));
const Contact = lazy(() => import("./components/opportunities/Contact"));

//part
const PrivateLabelBinding = lazy(() => import("./components/part/PrivateLabelBinding"));
const PartExtended = lazy(() => import("./components/part/PartExtended"));

//pricelist
const PriceList = lazy(() => import("./components/pricelist/PriceList"));
const PriceLists = lazy(() => import("./components/pricelist/PriceLists"));
const PrivateLabel = lazy(() => import("./components/pricelist/PrivateLabel"));
const PrivateLabelBy = lazy(() => import("./components/pricelist/PrivateLabelBy"));

//pricerequest
const PriceRequestAll = lazy(() => import("./components/pricerequest/PriceRequestAll"));
const PriceRequest = lazy(() => import("./components/pricerequest/PriceRequest"));
const CpData = lazy(() => import("./components/pricerequest/CpData"));

//pricing
const CustomerDiscount = lazy(() => import("./components/pricing/CustomerDiscount"));
const CustomerDiscountD = lazy(() => import("./components/pricing/CustomerDiscountD"));
const CustomerDiscountEditor = lazy(() => import("./components/pricing/customerdiscounteditor/CustomerDiscountEditor"));
const CustomerDiscountEditorLog = lazy(() => import("./components/pricing/customerdiscounteditor/CustomerDiscountEditorLog"));

//quoting

const QuotingAll = lazy(() => import("./components/quoting/QuotingAll"));
const QuotingPost = lazy(() => import("./components/quoting/QuotingPost"));
const QuotingPostRFQ = lazy(() => import("./components/quoting/QuotingPostRFQ"));
const QuotingPostOpp = lazy(() => import("./components/quoting/QuotingPostOpp"));
const PriceLookUp = lazy(() => import("./components/quoting/PriceLookup"));

//reports
const ProductTable = lazy(() => import("./components/reports/product/ProductTable"));
const MarketTable = lazy(() => import("./components/reports/market/MarketTable"));
const PartsTable = lazy(() => import("./components/reports/parts/PartsTable"));
const AllCustomerSales = lazy(() => import("./components/reports/customersaleshistory/AllCustomerSales"));
const ProductionStatus = lazy(() => import("./components/reports/productionstatus/ProductionStatus"));
const AllOpenOrders = lazy(() => import("./components/reports/productionstatus/AllOpenOrders"));
const CustomerFiveYearSales= lazy(() => import("./components/reports/customerfiveyearsales/CustomerFiveYearSales"));
const TestPivot = lazy(() => import("./components/reports/salespivot/TestPivot"));
const SalesCustomerTable = lazy(() => import("./components/reports/salescustomer/SalesCustomerTable"));
const OpenOrderTest = lazy(() => import("./components/reports/productionstatus/OpenOrderTest"));
const AssignedToDistributor = lazy(() => import("./components/reports/assignedtodistributor/AssignedToDistributor"));
const CustomerInvoiceSummary = lazy(() => import("./components/reports/customerinvoicesummary/CustomerInvoiceSummary"));
const CustomerSalesByRegion = lazy(() => import("./components/reports/customersalesbyregion/CustomerSalesByRegion"));
const RecentInvoice = lazy(() => import("./components/reports/recentinvoice/RecentInvoice"));
const SalesPivot = lazy(() => import("./components/reports/salespivot/SalesPivot"));
const AllReports = lazy(() => import("./components/reports/allreports/AllReports"));
const DiscountOverview = lazy(() => import("./components/reports/discountoverview/DiscountOverview"));
const DiscountOverviewDomestic = lazy(() => import("./components/reports/discountoverview/DiscountOverviewDomestic"));
const CustomerNetSalesByYear = lazy(() => import("./components/reports/customernetsalesbyyear/CustomerNetSalesByYear"));
const MarketSalesByMonth = lazy(() => import("./components/reports/marketsalesbymonth/MarketSalesByMonth"));
const CustomerSegmentSalesByYear = lazy(() => import("./components/reports/customersegmentsalesbyyear/CustomerSegmentSalesByYear"));
const MarketSalesByMonthThousandRound = lazy(() => import("./components/reports/marketsalesbymonth/MarketSalesByMonthThousandRound"));

//reusable
const CustomerOrderTest = lazy(() => import("./components/reusable/customerorder/customerorder"));
const AllMeetings = lazy(() => import("./components/reusable/meetings/AllMeetings"));
const GlobalSearchPage = lazy(() => import("./components/reusable/globalsearch/GlobalSearchPage"));

//stockingorder
const StockingOrders = lazy(() => import("./components/stockingorder/StockingOrders"));

//styles

//table

//tableexports

//tasks
const ViewTasks = lazy(() => import("./components/tasks/viewtasks/ViewTasks"));
const UserTasks = lazy(() => import("./components/tasks/viewtasks/UserTasks"));
const AssignedTasks = lazy(() => import("./components/tasks/viewtasks/AssignedTasks"));
const CreateOppTask = lazy(() => import("./components/tasks/createtasks/CreateOppTask"));
const TaskPage = lazy(() => import("./components/tasks/task-page"));
const TaskPageMid = lazy(() => import("./components/tasks/task-page-mid"));

//test
const SearchPostTest = lazy(() => import("./components/test/SearchPostTest"));
const Theming = lazy(() => import("./components/test/Theming"));
const ApiTest = lazy(() => import("./components/test/ApiTest"));
const ApiTestObject = lazy(() => import("./components/test/ApiTestObject"));
const TaggingTest = lazy(() => import("./components/test/TaggingTest"));
const BootStrapTest = lazy(() => import("./components/test/BootStrapTest"));
const Grid = lazy(() => import("./components/test/Grid"));
const BootStrapModalTest = lazy(() => import("./components/test/BootStrapModalTest"));
const UserTest = lazy(() => import("./components/test/usertestken"));
const DashboardTest = lazy(() => import("./components/test/dashboardtestken"));
const TestBucket = lazy(() => import("./components/test/testgraphs/TestBucket"));
const LineTestGraph = lazy(() => import("./components/test/testgraphs/LineGraphTest"));
const LineGraphTestTwo = lazy(() => import("./components/test/testgraphs/LineGraphTestTwo"));
const TestDashBoard = lazy(() => import("./components/test/testgraphs/TestDashboard"));
const PiechartTest = lazy(() => import("./components/test/testgraphs/PiechartTest"));
const PiechartSectorTest = lazy(() => import("./components/test/testgraphs/PiechartSectorTest"));
const BarGraphTest = lazy(() => import("./components/test/testgraphs/BarGraphTest"));
const HomeCloneDashboard = lazy(() => import("./components/test/HomeCloneDashboard"));
const AccountClassificationTest = lazy(() => import("./components/test/testgraphs/AccountClassificationTest"));
const UploadTestPage = lazy(() => import ("./components/test/UploadTestPage"))

//trips
const CreateTrip = lazy(() => import("./components/trips/CreateTrip"));
const ViewTrips = lazy(() => import("./components/trips/ViewTrips"));
const PostTrip = lazy(() => import("./components/trips/PostTrip"));
const PutTrip = lazy(() => import("./components/trips/PutTrip"));

//uploadsdownloads
const UploadFile = lazy(() => import("./components/uploadsdownloads/UploadFile"));
const ViewAllDigitalAssets = lazy(() => import("./components/uploadsdownloads/ViewAllDigitalAssets"));

//user

//users
const EditUser = lazy(() => import("./components/users/EditUser"));
const AllUsers = lazy(() => import("./components/users/AllUsers"));
const ChangePassword = lazy(() => import("./components/users/ChangePassword"));
const AddUser = lazy(() => import("./components/users/AddUser"));
const UsersAdminPanel = lazy(() => import("./components/users/UsersAdminPanel"));
const AdminUserPut = lazy(() => import("./components/users/AdminUserPut"));
const UserProfilePage = lazy(() => import("./components/users/UserProfilePage"));

//version
const Version = lazy(() => import("./components/version/Version"));

//visual
const VisualContacts = lazy(() => import("./components/visual/contactsvisual/VisualContacts"));
const VisualContactsAll = lazy(() => import("./components/visual/contactsvisual/VisualContactsAll"));
const VisualContactPage = lazy(() => import("./components/visual/contactsvisual/visual-contact-page"));


export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Router>
        <Suspense fallback={<div></div>}>
        {/* <Suspense fallback={<div><h1 style={{ textAlign: "center", fontSize: "4rem" }}> Loading...</h1></div>}> */}
        <Switch>

        <PrivateRoute path="/visualcontact/:id" component={VisualContactPage} />

        <PrivateRoute path="/accounts" component={AccountList} />

        <PrivateRoute path="/bootstrapmodaltest" component={BootStrapModalTest} />

        <PrivateRoute path="/linetestgraph" component={LineTestGraph} />

        <PrivateRoute path="/rfq" component={QuotingPostRFQ} />

        <PrivateRoute path="/foldercheck" component={FolderCheck} />

        <PrivateRoute path="/globalsearch" component={GlobalSearchPage} />

        <PrivateRoute path="/forecastannualusageupdate/:id" component={ForecastAnnualUsageUpdate} />

        <PrivateRoute path="/allaccounts" component={FullAccountList} />

        <PrivateRoute path="/group" component={Group} />

        <PrivateRoute path="/grouplist" component={GroupList} />

        <PrivateRoute path="/homeclonedashboard" component={HomeCloneDashboard} />

        <PrivateRoute path="/visualcontactsall" component={VisualContactsAll} />

        <PrivateRoute path="/testbucket" component={TestBucket} />

        <PrivateRoute path="/visualcontacts" component={VisualContacts} />

        <PrivateRoute path="/piecharttest" component={PiechartTest} />

        <PrivateRoute path="/rmaprice" component={RMAPrice} />

        <PrivateRoute path="/customerdiscountlog" component={CustomerDiscountEditorLog} />

        <PrivateRoute path="/deletedaccounts" component={DeletedAccounts} />

        <PrivateRoute path="/part/:id" component={PartExtended} />

        <PrivateRoute path="/profile/:id" component={UserProfilePage} />

        <PrivateRoute path="/processpartimages" component={ProcessPartImages} />

        <PrivateRoute path="/customerdiscounteditor" component={CustomerDiscountEditor} />

        <PrivateRoute path="/stockingorders" component={StockingOrders} />

        <PrivateRoute path="/customersegmentsales" component={CustomerSegmentSalesByYear} />

        <PrivateRoute path="/admin/users" component={UsersAdminPanel} />

        <PrivateRoute path="/admin/user" component={AdminUserPut} />

        <PrivateRoute path="/sourcepassticket" component={CRMSourcePass} />

        <PrivateRoute path="/commissions/payout/:id" component={InternalSalesCommissionPayout} />

        <PrivateRoute path="/commissions/payouttest/:id" component={InternalSalesBonusCommissionPayoutTest} />

        <PrivateRoute path="/commissions/rule/:id" component={CommissionRuleViewer} />

        <PrivateRoute path="/commissions/detail/:type/:id" component={SalesBonusDetail} />
        
        <PrivateRoute path="/commissionsadmin" component={SalesBonusAdminPage} />

        <PrivateRoute path="/commissions/:id" component={SalesBonusPage} />

        <PrivateRoute path="/devlog" component={DevLog} />

        <PrivateRoute path="/bargraphtest" component={BarGraphTest} />

        <PrivateRoute path="/devtools" component={DevToolsHome} />

        <PrivateRoute path="/testdashboard" component={TestDashBoard} />

        <PrivateRoute path="/linetestgraphtwo" component={LineGraphTestTwo} />

        <PrivateRoute path="/crmfeedback" component={CRMFeedback} />

        <PrivateRoute path="/softdelete" component={SoftDelete} />

        <PrivateRoute path="/undelete" component={UnDelete} />

        <PrivateRoute path="/updatedevlog" component={UpdateDevLog} />

        <PrivateRoute path="/marketsalesbymonth" component={MarketSalesByMonth} />

        <PrivateRoute path="/piechartsectortest" component={PiechartSectorTest} />

        <PrivateRoute path="/consolidatedsalesreport" component={MarketSalesByMonthThousandRound} />

        <PrivateRoute path="/customersalesbyyear" component={CustomerNetSalesByYear} />

        <PrivateRoute path="/createaccount" component={CreateAccount} />

        <PrivateRoute path="/forecastsummarypivot/:id" component={ForecastSummaryPivot} />

        <PrivateRoute path="/discountoverviewdomestic" component={DiscountOverviewDomestic} />

        <PrivateRoute path="/updateaccount" component={AccountInfo} />

        <PrivateRoute path="/contact/:id" component={Contact} />

        <PrivateRoute path="/bootstraptest" component={BootStrapTest} />

        <PrivateRoute path="/unprocessedcontactdata" component={UnprocessedContactData} />

        <PrivateRoute path="/allspam" component={AllSpam} />

        <PrivateRoute path="/contactformdata" component={ContactData} />

        <PrivateRoute path="/showrevisions/:id" component={ShowRevisions} />

        <PrivateRoute path="/forecastsummary/:object/:id" component={ForecastSummaryByYear} />

        <PrivateRoute path="/forecast/alltodo" component={ForecastAllGroups} />

        <PrivateRoute path="/forecast/todo" component={ForecastUserGroups} />

        <PrivateRoute path="/openordertest" component={OpenOrderTest} />

        <PrivateRoute path="/accountclassificationtest" component={AccountClassificationTest} />

        <PrivateRoute path="/forecastcontrolpanel/:id" component={ForecastControlPanelPage} />

        <PrivateRoute path="/forecastsubmissions" component={ForecastSubmissions} />

        <PrivateRoute path="/forecast/summary/detail/:id" component={ForecastSummaryDetail} />

        <PrivateRoute path="/forecast/summary/revenue/:id" component={ForecastSummaryRevenue} />

        <PrivateRoute path="/forecast/summary/preview/:id" component={ForecastSummaryPreview} />

        <PrivateRoute path="/forecast/summarytest/detail/:id" component={ForecastSummaryDetailPage} />

        <PrivateRoute path="/forecast/:id" component={Forecast} />

        <PrivateRoute path="/edi/order/" component={PurchaseOrder} />

        <PrivateRoute path="/edi/orders" component={EdiOrders} />

        <PrivateRoute path="/allcontactformdata" component={AllContactData} />

        <PrivateRoute path="/arrowtest" component={ArrowTest} />

        <PrivateRoute path="/manual/:id" component={ManualPage} />

        <PrivateRoute path="/sds/:id" component={SdsPage} />

        <PrivateRoute path="/allmanuals" component={AllManuals} />

        <PrivateRoute path="/admintools" component={AdminTools} />

        <PrivateRoute path="/assignedtodistributor" component={AssignedToDistributor} />

        <PrivateRoute path="/allsds" component={AllSds} />

        <PrivateRoute path="/createmanual" component={CreateManualPage} />

        <PrivateRoute path="/usertestken" component={UserTest} />

        <PrivateRoute path="/dashboardtestken" component={DashboardTest} />

        <PrivateRoute path="/createsds" component={CreateSDSPage} />

        <PrivateRoute path="/marketingtools" component={MarketingTools} />

        <PrivateRoute path="/cases" component={ViewCases} />

        <PrivateRoute path="/meetings" component={AllMeetings} />

        <PrivateRoute path="/customersalesbyregion" component={CustomerSalesByRegion} />

        <PrivateRoute path="/FollowFeed" component={FollowFeed} />

        <PrivateRoute path="/task/:id" component={TaskPage} />

        <PrivateRoute path="/taskmid/:id" component={TaskPageMid} />

        <PrivateRoute path="/meeting/:id" component={Meeting} />

        <PrivateRoute path="/createmeeting" component={CreateMeetingPage} />

        <PrivateRoute path="/privatelabelbinding" component={PrivateLabelBinding} />

        <PrivateRoute path="/masterlog" component={MasterLog} />

        <PrivateRoute path="/linkco" component={LinkCOfromVisualPage} />

        <PrivateRoute path="/colink" component={COLinkPage} />

        <PrivateRoute path="/customerorder" component={ViewCustomerOrderPage} />

        <PrivateRoute path="/customerorders" component={ViewCustomerOrdersPage} />

        <PrivateRoute path="/customerordertest" component={CustomerOrderTest} />

        <PrivateRoute path="/allreports" component={AllReports} />

        <PrivateRoute path="/case/:id" component={CasePage} />

        <PrivateRoute path="/createcase" component={PostCases} />

        <PrivateRoute path="/discountoverviewinternational" component={DiscountOverview} />

        <PrivateRoute path="/salespivot" component={SalesPivot} />

        <PrivateRoute path="/testpivot" component={TestPivot} />

        <PrivateRoute path="/addquote" component={QuotingPost} />

        <PrivateRoute path="/quotingpostopp" component={QuotingPostOpp} />

        <PrivateRoute path="/quotes" component={QuotingAll} />

        <PrivateRoute path="/customerfiveyearsales" component={CustomerFiveYearSales} />

        <PrivateRoute path="/customerdiscount" component={CustomerDiscount} />

        <PrivateRoute path="/customerdiscountd" component={CustomerDiscountD} />

        <PrivateRoute path="/recentinvoice" component={RecentInvoice} />

        <PrivateRoute path="/youropenorders" component={ProductionStatus} />

        <PrivateRoute path="/openorders" component={AllOpenOrders} />

        <PrivateRoute path="/latest" component={Latest} />

        <PrivateRoute path="/pricelookup" component={PriceLookUp} />

        <PrivateRoute path="/taggingtest" component={TaggingTest} />

        <PrivateRoute path="/createopptask" component={CreateOppTask} />

        <PrivateRoute path="/createdtasks" component={AssignedTasks} />

        <PrivateRoute path="/historic_crm_record_task" component={HistoricTask} />

        <PrivateRoute path="/historic_crm_record_account" component={HistoricAccount} />

        <PrivateRoute path="/apitest" component={ApiTest} />

        <PrivateRoute path="/apitestobject" component={ApiTestObject} />

        <PrivateRoute path="/historic_crm" component={HistoricTypes} />

        <PrivateRoute path="/historic_crm_table" component={HistoricTable} />

        <PrivateRoute path="/historic_crm_record" component={HistoricRecord} />

        <PrivateRoute path="/historic_crm_opportunity" component={HistoricOpportunities} />

        <PrivateRoute path="/historic_crm_tasks" component={HistoricTasks} />

        <PrivateRoute path="/historic_crm_users" component={HistoricUsers} />

        <PrivateRoute path="/historic_crm_accounts" component={HistoricAccounts} />

        <PrivateRoute path="/historic_crm_leads" component={HistoricLeads} />

        <PrivateRoute path="/historic_crm_cases" component={HistoricCases} />

        <PrivateRoute path="/historic_crm_user_tasks" component={HistoricUserTasks} />

        <PrivateRoute path="/allpricerequests" component={PriceRequestAll} />

        <PrivateRoute path="/privatelabels" component={PrivateLabel} />

        <PrivateRoute path="/privatelabel" component={PrivateLabelBy} />

        <PrivateRoute path="/viewalldigitalassets" component={ViewAllDigitalAssets} />

        <PrivateRoute path="/marketexpenses" component={ViewAllMarketExpenses} />

        <PrivateRoute path="/updatemarketexpense" component={UpdateMarketExpense} />

        <PrivateRoute path="/createmarketexpense" component={CreateMarketExpense} />

        <PrivateRoute path="/CustomerInvoiceSummary" component={CustomerInvoiceSummary} />

        <PrivateRoute path="/custompricing" component={CpData} />

        <PrivateRoute path="/createuser" component={AddUser} />

        <PrivateRoute path="/allopportunities" component={AllOpportunities} />

        <PrivateRoute path="/pricerequest" component={PriceRequest} />

        <PrivateRoute path="/usertasks" component={UserTasks} />

        <PrivateRoute path="/notificationlog" component={NotificationLog} />

        <PrivateRoute path="/pricelist" component={PriceList} />

        <PrivateRoute path="/pricelists" component={PriceLists} />

        <PrivateRoute path="/help" component={Help} />

        <PrivateRoute path="/version" component={Version} />

        <PrivateRoute path="/posttrip" component={PostTrip} />

        <PrivateRoute path="/puttrip" component={PutTrip} />

        <PrivateRoute path="/marketreport" component={MarketTable} />

        <PrivateRoute path="/productreport" component={ProductTable} />

        <PrivateRoute path="/customerinvoicereport" component={AllCustomerSales} />

        <PrivateRoute path="/logall" component={LogAll} />

        <PrivateRoute path="/salescustomerinvoicereport" component={SalesCustomerTable} />

        <PrivateRoute path="/usersleads" component={UsersLeads} />

        <PrivateRoute path="/viewcustomers" component={CustomerTable} />

        <PrivateRoute path="/customerhome" component={CustomerHome} />

        <PrivateRoute path="/customerinvoicedetail" component={CustomerInvoiceDetail} />

        <PrivateRoute path="/usersopportunities" component={UsersOpportunities} />

        <PrivateRoute path="/opportunity" component={OpportunitySplitScr} />

        <PrivateRoute path="/searchpost" component={SearchPostTest} />

        <PrivateRoute path="/leads" component={AllLeads} />

        <PrivateRoute path="/updatelead" component={LeadPage} />

        <PrivateRoute path="/createlead" component={CreateLead} />

        <PrivateRoute path="/viewtasks" component={ViewTasks} />

        <PrivateRoute path="/updatetasks" component={TaskPageMid} />

        <PrivateRoute path="/uploadtest" component={UploadFile} />

        <PrivateRoute path="/grid" component={Grid} />

        <PrivateRoute path="/theming" component={Theming} />

        <PrivateRoute path="/testnote" component={PutNote} />

        <PrivateRoute path="/createnewnote" component={CreateNote} />

        <PrivateRoute path="/createopportunity" component={CreateOpportunity} />

        <PrivateRoute path="/account" component={MobileDesktopAccountPage} />

        <Redirect from="/opportunitylist" to={"/account/"+window.location.search.replace(`account_id`, `id`)} />

        <PrivateRoute path="/updateopportunity" component={UpdateOpportunity} />

        <PrivateRoute path="/parts" component={PartsTable} />

        <PrivateRoute path="/createtrip" component={CreateTrip} />

        <PrivateRoute path="/viewtrips" component={ViewTrips} />

        <PrivateRoute path="/marketexpense" component={MarketExpense} />

        <PrivateRoute path="/edituser" component={EditUser} />

        <PrivateRoute path="/allusers" component={AllUsers} />

        <PrivateRoute path="/changepassword" component={ChangePassword} />

        <Route path="/login" component={Login} />

        <Route path="/uploadtestpage" component={UploadTestPage} />

        <PrivateRoute exact path="/" component={Home} />

        <Route component={NotFound} />

        </Switch>

        </Suspense>
      </Router>
    );
  }
}
